import { Fragment } from 'react';

import { Typography } from '@core/UI/Typography';

import { getIndicatorColor } from '@features/dynamicTable/helpers/getIndicatorColor';
import { IDynamicTableCell } from '@features/dynamicTable/types';

import * as UI from './Section.styles';
import { ISectionProps } from './types';

const formatValue = (cell: IDynamicTableCell): string => String(cell.value);

const Section = (props: ISectionProps) => {
  const { dividerSection } = props;

  return (
    <>
      {dividerSection.title && (
        <UI.SectionDividerTitle>
          <Typography color="chambrayBlue" type="p1">
            {dividerSection.title}
          </Typography>
        </UI.SectionDividerTitle>
      )}

      {dividerSection.sections.map((section) => (
        <Fragment key={section.id}>
          <UI.Heading indicatorColors={getIndicatorColor(section.color)}>
            <Typography color="chambrayBlue" type="p1">
              {section.title}
            </Typography>
          </UI.Heading>

          <UI.Container>
            {section.blocks.map(({ title, subBlocks, id }) => (
              <Fragment key={id}>
                {title && (
                  <UI.Title>
                    {title}
                  </UI.Title>
                )}

                {subBlocks.map(({ title: subtitle, id: subBlockId, rows }) => (
                  <Fragment key={subBlockId}>
                    {subtitle && (
                      <UI.SubTitle>
                        {subtitle}
                      </UI.SubTitle>
                    )}

                    {rows.map(({ secondaryValue, footnoteNo, caption, value, id: rowId }) => (
                      <UI.Grid key={rowId}>
                        <UI.Caption>
                          <UI.CaptionValue>
                            {caption}
                          </UI.CaptionValue>

                          {footnoteNo && (
                            <UI.Sup>
                              {footnoteNo}
                            </UI.Sup>
                          )}

                          {secondaryValue && (
                            <UI.CaptionSecondaryValue>
                              {` ${secondaryValue}`}
                            </UI.CaptionSecondaryValue>
                          )}
                        </UI.Caption>

                        <UI.Value indicatorColors={getIndicatorColor(value.color)}>
                          <UI.ColorIndicator>
                            <UI.ValueTypography>
                              {formatValue(value)}

                              {value.footnoteNo && (
                                <sup>
                                  {value.footnoteNo}
                                </sup>
                              )}
                            </UI.ValueTypography>

                            {(value.subValues) && (
                              <UI.SecondaryValue>
                                {`(${value.subValues[0].value})`}
                              </UI.SecondaryValue>
                            )}
                          </UI.ColorIndicator>
                        </UI.Value>
                      </UI.Grid>
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </UI.Container>
        </Fragment>
      ))}
    </>
  );
};

export default Section;
