import Section from '@features/dynamicTable/components/MobileView/components/Section';
import { IMobileViewProps } from '@features/dynamicTable/components/MobileView/types';

import * as UI from './MobileView.styles';

const MobileView = (props: IMobileViewProps) => {
  const { data: sections, styles } = props;

  return (
    <UI.Wrapper styles={styles}>
      {sections.map((dividerSection) => (
        <Section dividerSection={dividerSection} key={dividerSection.id} />
      ))}
    </UI.Wrapper>
  );
};

export default MobileView;
