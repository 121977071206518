import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale('ru', ru);
dayjs.tz.setDefault('Europe/Moscow');

export const format = (sourceDate: number | string | null | undefined, template: string): string => {
  const date = dayjs(sourceDate).tz();
  return date.isValid() ? date.format(template) : '';
};

export const formatDateShort = (date: string) => format(date, 'D MMM YYYY').replace('.', '');
