import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';

import {
  commonRowCss,
  commonTableCellCss,
  IRow,
  stickyTableCellCss,
} from '@features/dynamicTable/components/DesktopView/components/Row/Row.styles';
import { IDynamicTableCellCoords } from '@features/dynamicTable/types';

interface HeaderCellProps {
  gridCoords?: IDynamicTableCellCoords['gridCoords'];
  columnsCount: number;
  rowsCount: number;
  colspan: number;
  rowspan: number;
}

const getBorderRadius = (gridCoords: IDynamicTableCellCoords['gridCoords'], columnsCount: number, rowsCount: number): string => {
  if (!gridCoords) return '';
  const { xStart, xEnd, yStart, yEnd } = gridCoords;
  const borders: string[] = [];

  if (xStart === 0 && yStart === 0) {
    borders.push('border-top-left-radius: 8px;');
  }
  if (xEnd === columnsCount && yStart === 0) {
    borders.push('border-top-right-radius: 8px;');
  }
  if (xEnd === columnsCount && yEnd === rowsCount) {
    borders.push('border-bottom-right-radius: 8px;');
  }
  if (xStart === 0 && yEnd === rowsCount) {
    borders.push('border-bottom-left-radius: 8px;');
  }

  return borders.join('\n');
};

const getPositionSticky = (gridCoords: IDynamicTableCellCoords['gridCoords']): FlattenSimpleInterpolation | string => {
  if (!gridCoords) return '';
  return gridCoords.xStart === 0 ? stickyTableCellCss : '';
};

const getPadding = (gridCoords: IDynamicTableCellCoords['gridCoords'], columnsCount: number, rowsCount: number): string => {
  if (!gridCoords) return '';
  const { xStart, xEnd, yStart, yEnd } = gridCoords;
  const paddings: string[] = [];
  const paddingValue = '16px';

  if (xStart === 0) {
    paddings.push(`padding-left: ${paddingValue};`);
  }

  if (xEnd === columnsCount) {
    paddings.push(`padding-right: ${paddingValue};`);
  }

  if (yStart === 0) {
    paddings.push(`padding-top: ${paddingValue};`);
  }

  if (yEnd === rowsCount) {
    paddings.push(`padding-bottom: ${paddingValue};`);
  }

  return paddings.join('\n');
};

export const Value = styled(Typography).attrs({ type: 'p2' })`
  color: ${colors.mystic};
`;

export const SecondaryValueWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const SecondaryValue = styled(Typography).attrs({ type: 'p2', color: 'mystic' })`
  flex-grow: 1;
  padding: 0px 3px;
`;

export const Sup = styled.sup`
  position: relative;
  top: -3px;
  vertical-align: top;
`;

export const HeaderCell = styled.div<HeaderCellProps>`
  ${commonTableCellCss};
  background-color: ${colors.chambrayBlue};
  padding: 0;
  ${({ colspan = 1, rowspan = 1 }) => css`
    grid-column: span ${colspan};
    grid-row: span ${rowspan};
  `}

  ${({ gridCoords, columnsCount, rowsCount }) => css`
    ${getBorderRadius(gridCoords, columnsCount, rowsCount)};
    ${getPositionSticky(gridCoords)};
    ${getPadding(gridCoords, columnsCount, rowsCount)};
  `}

  &:first-child {
    background-color: ${colors.chambrayBlue};
  }
`;

export const HeaderRow = styled.div<IRow>`
  ${commonRowCss};
`;

export const Header = styled.div<{ width: string }>`
  position: sticky;
  top: 0;
  z-index: 1;
  min-width: 100%;

  ${({ width }) => width && css`
    width: ${width};
  `}
`;
