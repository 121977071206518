import { css } from 'styled-components';

import colors from '@core/constants/colors';

export const horizontalScrollStyles = css`
  padding-bottom: 5px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 1em;
  }

  &::-webkit-scrollbar-track {
    border-bottom: 1px solid ${colors.mystic};
  }

  &::-webkit-scrollbar-thumb {
    border-bottom: 3px solid ${colors.chambrayBlue};
  }
`;

export const thickScrollStyles = css`
  overflow: auto;

  &::-webkit-scrollbar {
    width: 1em;
    height: 1em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.chambrayBlue};
    background-clip: padding-box;
    border-radius: 0.5em;
    border-width: 0.25em;
    border-style: solid;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-left-color: ${colors.white};
    border-right-color: ${colors.white};
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: ${colors.white};
    border-top-color: ${colors.white};
  }

  &::-webkit-scrollbar-track {
    background-color: ${colors.mystic};
    background-clip: padding-box;
    border-radius: 0.5em;
    border: 0.25em solid ${colors.white};
  }
`;
