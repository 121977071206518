import { getIndicatorColor } from '@features/dynamicTable/helpers/getIndicatorColor';

import * as UI from './Row.styles';
import { IRowProps } from './types';

const Row = (props: IRowProps) => {
  const { row, valuesColumnsSizes, isSubHeader, isDivider } = props;
  const { cells, subRows } = row;
  const hasSubRows = Array.isArray(subRows) && subRows.length !== 0;

  return (
    <UI.RowContainer hasSubRows={hasSubRows}>
      <UI.Row
        columnsSizes={valuesColumnsSizes}
        isDivider={isDivider}
        isSubHeader={isSubHeader}
      >
        {cells.map((cell) => (
          <UI.TableCell
            colspan={cell.colspan}
            indicatorColors={getIndicatorColor(cell.color)}
            key={cell.id}
          >
            <UI.ColorIndicator>
              <UI.Value>
                {String(cell.value)}

                {cell.footnoteNo && (
                  <UI.Sup>
                    {cell.footnoteNo}
                  </UI.Sup>
                )}
              </UI.Value>

              {cell.subValues && (
                <UI.SecondaryValueWrapper>
                  {cell.subValues.map((subValue) => (
                    <UI.SecondaryValue indicatorColors={getIndicatorColor(subValue.color)} key={subValue.id}>
                      (
                      {subValue.value}
                      )
                    </UI.SecondaryValue>
                  ))}
                </UI.SecondaryValueWrapper>
              )}
            </UI.ColorIndicator>
          </UI.TableCell>
        ))}
      </UI.Row>

      {hasSubRows && (
        <UI.SubRows>
          {subRows.map(({ cells: subRowCells, id: subRowId }) => (
            <UI.SubRow columnsSizes={valuesColumnsSizes} key={subRowId}>
              {subRowCells.map((cell) => (
                <UI.TableCell
                  colspan={cell.colspan}
                  indicatorColors={getIndicatorColor(cell.color)}
                  key={cell.id}
                >
                  <UI.ColorIndicator>
                    <UI.Value>
                      {String(cell.value)}

                      {cell.footnoteNo && (
                        <UI.Sup>
                          {cell.footnoteNo}
                        </UI.Sup>
                      )}
                    </UI.Value>

                    {cell.subValues && (
                      <UI.SecondaryValueWrapper>
                        {cell.subValues.map((subValue) => (
                          <UI.SecondaryValue indicatorColors={getIndicatorColor(subValue.color)} key={subValue.id}>
                            (
                            {subValue.value}
                            )
                          </UI.SecondaryValue>
                        ))}
                      </UI.SecondaryValueWrapper>
                    )}
                  </UI.ColorIndicator>
                </UI.TableCell>
              ))}
            </UI.SubRow>
          ))}
        </UI.SubRows>
      )}
    </UI.RowContainer>
  );
};

export default Row;
