import styled, { css } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import Down from '@core/assets/icons/down.svg';
import colors from '@core/constants/colors';

import { IIndicatorColor } from '@features/dynamicTable/helpers/getIndicatorColor';

export interface IRow {
  columnsSizes: string;
  isDivider?: boolean;
  isSubHeader?: boolean;
}

export const commonRowCss = css<IRow>`
  display: grid;
  grid-template-columns: ${({ columnsSizes }) => columnsSizes};
`;

export const stickyTableCellCss = css`
  text-align: start;
  position: sticky;
  left: 0;
  transform-style: preserve-3d;
  padding: 8px 0 8px 16px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: ${colors.white};
    transform: translateZ(-1px);
  }
`;

export const commonTableCellCss = css`
  text-align: center;
  word-break: break-word;
  padding: 8px 0px;
`;

export const DownIcon = styled(Down)`
  color: ${colors.riverBlue};
  transition: transform 0.2s ease-in-out 0s;
`;

export const OpenSubRowsButton = styled.button`
  position: absolute;
  top: 9px;
  right: 0;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 14px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const ColorIndicator = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 8px 0;
`;

export const Value = styled(Typography).attrs({ type: 'p2' })`
  color: ${colors.riverBlue};
`;

export const SecondaryValueWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5px;
`;

export const SecondaryValue = styled(Typography).attrs({ type: 'p2' })<{ indicatorColors?: IIndicatorColor }>`
  flex-grow: 1;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  color: ${colors.cadetBlue};
  padding: 0px 3px;

  ${({ indicatorColors }) => indicatorColors && css`
    ${indicatorColors.font && 'font-weight: bold;'}
    color: ${indicatorColors.font};
  `}
`;

export const Sup = styled.sup`
  position: relative;
  top: -3px;
  vertical-align: top;
`;

export const TableCell = styled.div<{ indicatorColors?: IIndicatorColor, colspan: number }>`
  ${commonTableCellCss};
  border-style: solid;
  border-color: ${colors.mystic};
  border-width: 0;

  &:first-child {
    font-weight: bold;
    ${stickyTableCellCss};
  }

  &:not(:first-child) {
    ${ColorIndicator} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  ${({ indicatorColors }) => indicatorColors && css`
    &&&:first-child {
      background-color: ${indicatorColors.background};
    }

    &:not(:first-child) {
      ${ColorIndicator} {
        background-color: ${indicatorColors.background};

        ${Value} {
          ${indicatorColors.font && 'font-weight: bold;'}
          color: ${indicatorColors.font};
        }
      }
    }
  `}

  ${({ colspan = 1 }) => css`
    grid-column: span ${colspan};
  `}
`;

export const Row = styled.div<IRow>`
  ${commonRowCss};

  ${TableCell} {
    border-top-width: 1px;
    border-bottom-width: 1px;

    &:first-child {
      border-radius: 8px 0 0 0;
      border-left-width: 1px;
      background-color: ${colors.aquaHaze};
      padding-right: 40px;

      &::before {
        top: -1px;
        left: -1px;
        bottom: -1px;
        right: 0;
      }
    }

    &:last-child {
      border-radius: 0 8px 0 0;
      border-right-width: 1px;
    }
  }

  ${({ isSubHeader }) => isSubHeader && css`
    margin-top: 8px;

    ${TableCell} {
      background-color: ${colors.aquaHaze};
    }
  `}

  ${({ isDivider }) => isDivider && css`
    ${TableCell} {
      background-color: ${colors.aquaHaze};

      ${ColorIndicator} {
        padding: 0;
      }

      ${Value} {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        white-space: nowrap;
      }
    }
  `}
`;

export const SubRow = styled.div<IRow>`
  ${commonRowCss};

  ${TableCell}:first-child {
    background-color: ${colors.aquaHaze};
  }
`;

export const SubRows = styled.div`
  display: none;
`;

export const RowContainer = styled.div<{ hasSubRows: boolean }>`
  border-radius: 8px;

  ${({ hasSubRows }) => (hasSubRows ? css`
    ${SubRows} {
      display: block;

      ${SubRow}:last-child {
        ${TableCell} {
          border-bottom-width: 1px;

          &:first-child {
            border-radius: 0 0 0 8px;

            &::before {
              top: 0;
              left: -1px;
              bottom: -1px;
              right: 0;
            }
          }

          &:last-child {
            border-radius: 0 0 8px 0;
          }
        }
      }

      ${TableCell} {
        &:first-child {
          border-left-width: 1px;
        }

        &:last-child {
          border-right-width: 1px;
        }
      }
    }

    ${DownIcon} {
      transform: rotate(180deg);
    }

    ${Row} ${TableCell} {
      border-bottom: none;
    }
  ` : css`
    ${Row} ${TableCell}:first-child {
      border-radius: 8px 0 0 8px;
    }

    ${Row} ${TableCell}:last-child {
      border-radius: 0 8px 8px 0;
    }
  `)}
`;
