import { useMemo } from 'react';

import * as UI from './DesktopView.styles';
import Header from './components/Header';
import Row from './components/Row';
import { IDesktopViewProps } from './types';

const DesktopView = (props: IDesktopViewProps) => {
  const { tableData, styles } = props;
  const { header, rows } = tableData;

  const [columnsSizes, width] = useMemo(() => {
    const firstCellWidth: number = 300;
    const otherCellWidth: number = 110;
    let frCount = 0;

    header[0].cells.forEach((cell) => {
      frCount += cell.colspan;
    });

    return [
      `${firstCellWidth}px ${`minmax(${otherCellWidth}px, 1fr) `.repeat(frCount - 1)}`,
      `${firstCellWidth + otherCellWidth * (frCount - 1)}px`,
    ];
  }, [header]);

  return (
    <UI.Wrapper styles={styles}>
      <Header columnsSizes={columnsSizes} header={header} width={width} />

      <UI.Container width={width}>
        {rows.map((row, index) => (
          <Row
            isDivider={row.divider}
            isSubHeader={index === 0}
            key={row.id}
            row={row}
            valuesColumnsSizes={columnsSizes}
          />
        ))}
      </UI.Container>
    </UI.Wrapper>
  );
};

export default DesktopView;
