import styled, { css } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { PAGE_PADDINGS } from '@core/constants/styles';

import { IIndicatorColor } from '@features/dynamicTable/helpers/getIndicatorColor';

export const SectionDividerTitle = styled.div`
  background-color: ${colors.aquaHaze};
  padding: 16px ${PAGE_PADDINGS.MOBILE}px;
`;

export const Heading = styled.div<{ indicatorColors?: IIndicatorColor }>`
  padding: ${PAGE_PADDINGS.MOBILE}px;
  background-color: ${colors.aquaHaze};

  ${({ indicatorColors }) => indicatorColors && css`
    background-color: ${indicatorColors.background};
    ${Typography} {
      color: ${colors.riverBlue};
    }
  `}
`;

export const Title = styled(Typography).attrs({ type: 'h3' })`
  padding: 8px ${PAGE_PADDINGS.MOBILE}px;
  margin-top: 8px;
`;

export const SubTitle = styled(Typography).attrs({ type: 'p1' })`
  color: ${colors.manatee};
  margin-bottom: 8px;
  text-align: center;
`;

export const Caption = styled(Typography).attrs({ type: 'p1' })``;

export const CaptionValue = styled.span``;

export const CaptionSecondaryValue = styled.span``;

export const Sup = styled.sup`
  position: relative;
  top: -3px;
  vertical-align: top;
`;

export const ColorIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ValueTypography = styled(Typography).attrs({ type: 'h3' })`
  font-size: 20px;
  color: ${colors.chambrayBlue};
  border-radius: 8px;
  padding: 4px 8px 0 8px;
  text-align: right;
`;

export const SecondaryValue = styled(Typography).attrs({ type: 'p2' })``;

export const Value = styled.div<{ indicatorColors?: IIndicatorColor }>`
  ${({ indicatorColors }) => indicatorColors && css`
    ${ValueTypography} {
      background-color: ${indicatorColors.background};
      color: ${indicatorColors.font};
    }

    ${SecondaryValue} {
      color: ${indicatorColors.font};
    }
  `}
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  padding: 0 ${PAGE_PADDINGS.MOBILE}px;

  &:first-child {
    margin-top: 16px;
  }

  & + & {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 16px;
  }
`;

export const Container = styled.div``;
