import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { thickScrollStyles } from '@core/styles/scroll.styles';

import { RowContainer } from './components/Row/Row.styles';

export const Container = styled.div<{ width?: string }>`
  min-width: 100%;
  max-height: 60vh;
  ${({ width }) => width && css`
    width: ${width};
  `}

  ${RowContainer} + ${RowContainer} {
    margin-top: 4px;
  }
`;

export const Wrapper = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  ${thickScrollStyles};

  ${({ styles }) => styles};
`;
