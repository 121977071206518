import colors from '@core/constants/colors';

import { IDynamicTableCell } from '../types';

export interface IIndicatorColor {
  background: string;
  font: string;
}

/*
  background ниже - это полупрозрачные оттенки основных цветов из палитры на белом фоне.
  Необходимо использовать именно в таком виде (без альфа-канала), т.к. генератор pdf (jspdf) не поддерживает
  альфа-канал в цвете.
 */
const indicatorColors: { [key: string]: IIndicatorColor } = {
  'default': {
    background: '',
    font: '',
  },
  '1': {
    background: '#EAF5DD',
    font: colors.green,
  },
  '2': {
    background: '#FEF6DF',
    font: colors.vividTangerine,
  },
  '3': {
    background: '#F9DADA',
    font: colors.bloodRed,
  },
};

export const getIndicatorColor = (color: IDynamicTableCell['color']): IIndicatorColor => (indicatorColors[color || 'default']);
