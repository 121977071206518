import { PagePreloader } from '@eisgs/preloader';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';
import { useMedia } from '@core/hooks/useMedia';

import MobileView from '@features/dynamicTable/components/MobileView';

import * as UI from './DynamicTablePage.styles';
import DesktopView from './components/DesktopView';
import { IDynamicTablePageProps } from './types';

const DynamicTablePage = (props: IDynamicTablePageProps) => {
  const { title, data, meta, desktopStyles, mobileStyles } = props;
  const isTablet = useMedia(BREAKPOINTS.TABLET, true);

  return meta.fetchError ? (
    <>
      <Typography type="h1">
        Извините, данные временно недоступны.
      </Typography>
    </>
  ) : (
    <>
      {meta.fetchLoading && (<PagePreloader hasDisabledScroll={false} />)}

      {title && (
        <UI.Title isTablet={isTablet}>
          {title}
        </UI.Title>
      )}

      {(data?.desktopData && isTablet) && (
        <DesktopView
          styles={desktopStyles}
          tableData={data.desktopData}
        />
      )}

      {(data?.mobileData && !isTablet) && (
        <MobileView
          data={data.mobileData}
          styles={mobileStyles}
        />
      )}
    </>
  );
};

export default DynamicTablePage;
