import { Fragment } from 'react';

import { IHeaderProps } from '@features/dynamicTable/components/DesktopView/components/Header/types';

import { buildGrid } from '../../mappers';

import * as UI from './Header.styles';

const Header = (props: IHeaderProps) => {
  const { header, columnsSizes, width } = props;
  const { header: headerWithCoords, headerMaxX, headerMaxY } = buildGrid(header);

  return (
    <UI.Header width={width}>
      <UI.HeaderRow columnsSizes={columnsSizes}>
        {headerWithCoords.map(({ cells, id: rowId }, index) => (
          <Fragment key={rowId}>
            {cells.map((cell) => {
              const {
                id: cellId,
                value,
                subValues,
                footnoteNo,
                colspan,
                rowspan,
                gridCoords,
              } = cell;

              return (
                <UI.HeaderCell
                  className={`header-row-${index + 1}`}
                  colspan={colspan}
                  columnsCount={headerMaxX}
                  gridCoords={gridCoords}
                  key={cellId}
                  rowsCount={headerMaxY}
                  rowspan={rowspan}
                >
                  <UI.Value>
                    {value}

                    {footnoteNo && (
                      <UI.Sup>
                        {footnoteNo}
                      </UI.Sup>
                    )}
                  </UI.Value>

                  {subValues && (
                    <UI.SecondaryValueWrapper>
                      {subValues.map((subValue) => <UI.SecondaryValue key={subValue.id}>{subValue.value}</UI.SecondaryValue>)}
                    </UI.SecondaryValueWrapper>
                  )}
                </UI.HeaderCell>
              );
            })}
          </Fragment>
        ))}
      </UI.HeaderRow>
    </UI.Header>
  );
};

export default Header;
