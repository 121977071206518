import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS, DEVICES, PAGE_PADDINGS } from '@core/constants/styles';

interface ITitleStylesProps {
  isTablet: boolean;
}

export const Title = styled(Typography).attrs(
  ({ isTablet }: ITitleStylesProps) => ({ type: isTablet ? 'h2' : 'p1' }),
)<ITitleStylesProps>`
  font-weight: bold;
  margin-bottom: 16px;

  @media ${DEVICES.MOBILE} {
    padding: 0 ${PAGE_PADDINGS.MOBILE}px;
  }

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: 24px;
  }
`;
